import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';




const InputField = withStyles((theme) => ({
    root: {     
      flex: 1,
      width: '100%',
      marginLeft: theme.spacing(1),
    },  
    input:{
        background: '#F2F2F2',
        borderRadius: 5,
        padding: 8,
        height: 33
    }
}))(InputBase);


  

export default InputField